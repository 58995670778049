.grid-bg {
    //background-image: url('/image/grid-bg.png'); 
    background-color: $blue;
    background-position: 50% top;
    background-size: cover;
    padding: 70px 0;
    font-size: 25px;
    line-height: 1.5;
    color: #fff;
    font-weight: 100;
    p {
        margin: 0;
    }

    @media(max-width: 1199px) {
        padding: 60px 0;
        font-size: 23px;
    }
    @media(max-width: 991px) {
        padding: 50px 0;
        font-size: 20px;
        line-height: 1.4;
    }
    @media(max-width: 991px) {
        padding: 40px 0;
        font-size: 18px;
        line-height: 1.3;
    }
    @media(max-width: 767px) {
        padding: 30px 0;
        font-size: 16px;
        line-height: 1.4;
    }
}

.grey-section{
    background: #f2f2f2;
}

.white-section, .grey-section{
    padding: 60px 0;
    @media(max-width: 991px) {
        padding: 50px 0;
    }
    @media(max-width: 767px) {
        padding: 40px 0;
    }
    @media(max-width: 575px) {
        padding: 30px 0;
    }
    .columns{
        h3{
            font-size: 50px;
            color: #000;
            margin-bottom: 40px;
            @media(max-width: 991px) {
                margin-bottom: 20px;
                font-size: 40px;
            }
            @media(max-width: 767px) {
                font-size: 30px;
            }
           
        }
        p{
            font-size: 17px;
            @media(max-width: 991px) {
                font-size: 16px;
            }
            @media(max-width: 767px) {
                font-size: 15px;
            }
        }

      
    }
}

.home-brands{
    .inner{
        background: #e5e3e3;
        padding: 30px 60px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        flex-basis: 100%;
        max-width: 100%;
        @media(max-width: 991px) {
            padding: 30px 40px;
        }
        @media(max-width: 575px) {
            padding: 30px 30px;
        }
        .image{
            margin-bottom: 20px;
        }
        .bottom{
            p{
                font-size: 15px;
                font-weight: normal;
                margin-bottom: 20px;;
            }
            .white-button{
                display: block;
                text-align: center;
                padding: 5px 20px;
                background: #fff;
                color: #666;
                &:hover, &:focus{
                    background: #46966a;
                    color: #fff;
                }
            }
        }

        &.item1{
            border-bottom: 40px solid #46966a;
            @media(max-width: 575px) {
                border-bottom: 20px solid #46966a;
            }
            .bottom{
                .white-button{
               
                    &:hover, &:focus{
                        background: #46966a;
                    }
                }
            }
            
        }
        &.item2{
            border-bottom: 40px solid #c4002a;
            @media(max-width: 575px) {
                border-bottom: 20px solid #c4002a;
            }
            .bottom{
                .white-button{
               
                    &:hover, &:focus{
                        background: #c4002a;
                    }
                }
            }
        }
    }
}