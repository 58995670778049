#header{
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);
    position: fixed;
    width: 100%;
    top: 0;
    background: #fff;
    
    left: 0;
    z-index: 1005;
    transition: .3s all cubic-bezier(.165,.84,.44,1);
    #logo{
        margin: 0;
        display: inline-block;
        a{
            display: block;
        }
        img{
            transition: .0s all cubic-bezier(.165,.84,.44,1);
            width: 300px;
                height: 55px;
        }
        @media(max-width: 991px){
            flex-shrink: 0;
            img{
                
                width: 205px;
                height: 38px;
            }
        }

        @media(max-width: 767px){
            flex-shrink: 0;
            img{
                
                width: 160px;
                height: 30px;
            }
        }
        
        @media(max-width: 450px){
           
            img{
                
                width: 160px;
                height: 30px;
            }
        }
        
    }

    &.compact{
        
        transition: .3s all cubic-bezier(.165,.84,.44,1);
        //transform: translateY(-44px);
        @media (max-width: 767px) {
            transform: translateY(0px);
          }
        #logo{
           
            
                img{
                    transition: .3s all cubic-bezier(.165,.84,.44,1);
                    width: 160px;
                    height: 30px;
                    
                }
        }
        
    }
    

    .black-bar{
        background: #000;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        padding: 10px 0;
        transform: scaleY(1);
        transform-origin: top;
        transition: .3s all cubic-bezier(.165,.84,.44,1);
        ul{
            margin: 0;
            li{
                padding: 0;
                a{
                    color: #fff;
                    font-size: 14px;
                    i{
                        color: #fff; 
                        font-size: 16px;
                    }
                    &:hover, &:focus{
                        color: #009edc;
                        i{
                            color: #009edc;
                        }

                    }
                }
            }
        }

        

        .contact-buttons{
            ul{
                li{
                    padding-left: 15px;
                    
                }
            }
        }
    }

    .inner{
        padding: 20px 0;
        background: #fff;
        position: relative;
        @media (max-width: 767px) {
            padding: 15px 0;
          }
        .menu{

            .toggle{
                font-size: 18px;
                font-weight: normal;
                &:hover{
                    color: $blue;
                    cursor: pointer
                }
            }
           
            flex-shrink: 0;
            margin-left: 30px;
            
            ul{
                margin: 0;
                li{
                    a{
                        
                        padding: 10px 10px;
                        text-transform: uppercase;
                        font-size: 15px;
                        color: #666;
                        font-weight: bold;
                        
                        &:hover, &:focus{
                            color: #000;
                            text-decoration: none;
                        }
                    }

                    &:last-child{
                        a{
                            padding-right: 0;
                        }
                    }
                }
            }

            @media(max-width: 767px){



                ul{
                    position: absolute;
                    opacity: 0;
                    visibility: hidden;
                    transform: scaleY(0);
                    transform-origin: top;
                    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
                    top: 100%;
                    width: 100%;
                    left: 0;
                    z-index: 10;
                    box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
                    padding: 20px;
                    background: #fff;
                    li{
                        display: block;
                        a{
                            padding: 10px 0;
                            display: block;
                            font-size: 16px;
                            text-align: center
                            
                        }
                    }
                }

                &.show{
                    ul{
                        opacity: 1;
                        visibility: visible;
                        transform: scaleY(1);
                    }
                }
                
            }
    
        }
    }


    

    



}

