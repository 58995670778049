@font-face {
	font-family: 'Swis';
	src: url('fonts/Swiss721BT-Thin.eot');
	src: url('fonts/Swiss721BT-Thin.eot?#iefix') format('embedded-opentype'),
		url('fonts/Swiss721BT-Thin.woff') format('woff'),
		url('fonts/Swiss721BT-Thin.ttf') format('truetype');
	font-weight: 100;    
	font-style: normal;    
}
     
@font-face {
	font-family: 'Swis';
	src: url('fonts/Swiss721BT-Light.eot');  
	src: url('fonts/Swiss721BT-Light.eot?#iefix') format('embedded-opentype'),
		url('fonts/Swiss721BT-Light.woff') format('woff'),
		url('fonts/Swiss721BT-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Swis';
	src: url('fonts/Swiss721BT-Roman.eot');
	src: url('fonts/Swiss721BT-Roman.eot?#iefix') format('embedded-opentype'),
		url('fonts/Swiss721BT-Roman.woff') format('woff'),
		url('fonts/Swiss721BT-Roman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Swis';
	src: url('fonts/Swiss721BT-Bold.eot');
	src: url('fonts/Swiss721BT-Bold.eot?#iefix') format('embedded-opentype'),
		url('fonts/Swiss721BT-Bold.woff') format('woff'),
		url('fonts/Swiss721BT-Bold.ttf') format('truetype');
	font-weight: bold;        
	font-style: normal;  
}   

@font-face {
    font-family: 'Swis';
    src: url('fonts/Swiss721BT-Medium.eot');
    src: url('fonts/Swiss721BT-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/Swiss721BT-Medium.woff') format('woff'),
        url('fonts/Swiss721BT-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}