$green : #009470;
$lightgreen : #67bfaa;
$blue : #005777;
$lightblue : #23a1d1;
$grey : #b7b7b7;
$darkgrey: #666666;
$white: #ffffff;
$pink: #c990a1;
$red: #b13c3f;
$lighterblue: #cddfe9;
$lightergreen: #b4dfd5;
$lighterred: #f0dde3;
$lightergray: #ebebeb;