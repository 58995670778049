@import "_fonts";
@import "_colours";
@import "_header";
@import "_footer";
@import "_home";

body {
  font-family: "Swis";
  color: #000000;
  font-size: 16px;
  line-height: 1.5;

  font-weight: 400;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #000;
  }

  padding-top: 95px;
  @media (max-width: 991px) {
    padding-top: 78px;
  }

  @media (max-width: 767px) {
    padding-top: 60px;
  }

  
  @media (max-width: 575px) {
    font-size: 15px;
    font-weight: 300;
  }

  .row {
    &:before,
    &:after {
      display: flex;
    }
  }

  h1 {
    color: #000;
    @media (max-width: 991px) {
      font-size: 35px;
    }
    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  .main-description {
    margin-bottom: 40px;
  }

  .form-group {
    flex-wrap: wrap;
  }

  .alert {
    padding: 15px;
    margin-top: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 0;
  }
}

body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.secondary-heading {
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.7;
  font-weight: 300;
  span {
    font-size: 30px;
  }
  h4 {
    display: inline-block;
    font-size: 30px;
    margin-top: 0;
    color: #000000;
    font-weight: 100;
  }
}
img {
  max-width: 100%;
}

a {
  text-decoration: none;

  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  i {
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}

.transition {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.font-medium {
  font-weight: 500;
}

.font-thin {
  font-weight: 100;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: normal;
}

.font-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}

.green-button {
  padding: 7px 15px;
  color: #fff;
  background: $green;
  border: 0px;
  border-radius: 0px;
  border: 0px solid $green;
  box-shadow: none;
  letter-spacing: 2px;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    background: darken($green, 10%);
    color: #fff;
  }
}

.red-button {
  padding: 7px 15px;
  color: #fff;
  background: #d40026;
  border: 0px;
  border-radius: 0px;
  border: 0px solid #d40026;
  box-shadow: none;
  font-weight: 400;
  letter-spacing: 2px;
  cursor: pointer;
  display: inline-block;
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    background: darken(#d40026, 10%);
    color: #fff;
  }
}
.blue-button {
  padding: 7px 15px;
  color: #fff;
  background: $lightblue;
  border: 0px;
  border-radius: 0px;
  border: 2px solid $lightblue;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    background: darken($lightblue, 10%);
    color: #fff;
  }
}

h1 {
  font-size: 45px;
  color: $blue;
  margin-bottom: 20px;
}

#content .description {
  b,
  strong {
    font-weight: 600;
  }
}

#slider {
  box-shadow: 0 20px 25px rgba(0,0,0,0.4);

  a.item{
    &hover, &:focus{
      text-decoration: none;
    }
  }
  .owl-nav {
    margin: 0;
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
  }
  .owl-prev,
  .owl-next {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    i {
      font-size: 30px;
      color: #005777;
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover {
      background: darken(#005777, 10%);
      outline: none;
      box-shadow: none;
      border: 0;
      i {
        color: #fff;
      }
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border: 0;
    }
  }

  .owl-next {
    right: 0;
  }
  .owl-prev {
    left: 0;
  }
  .owl-item{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    float: none;
      img{
          width: auto;
      }
  }
  .owl-stage{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
  }
  .item {
    min-height: 500px;
    flex-basis: 100%;
    background-color: #e5e3e4;
    background-size: cover;
    background-position: 50% 100%;
    background-repeat: no-repeat;

    @media (max-width: 1199px) {
      min-height: 400px;
    }
   
    @media (max-width: 767px) {
      min-height: 300px;
    }
    .inner-bg {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      min-height: 500px;
      @media (max-width: 1199px) {
        min-height: 400px;
      }
      
      @media (max-width: 767px) {
        min-height: 300px;
      }
      .inner {
        padding: 30px;
        max-width:450px;
        margin: 60px 0;
        background: rgba(0,0,0, 0.5);
        @media (max-width: 767px) {
          margin: 30px 0;
          margin-bottom: 80px;
          padding: 20px;
        }
        img{
          display: block;
        }

        h1{
          font-size: 40px;
          font-weight: bold;
          color: #fff;
          margin-top: 0;
          @media (max-width: 767px) {
            font-size: 30px;
          }
        }

        h3 {
          font-size: 30px;
          font-weight: 100;
          color: #fff;
          @media (max-width: 991px) {
            font-size: 25px;
          }
          @media (max-width: 767px) {
            font-size: 20px;
            margin-bottom: 20px;
            font-weight: 300;            margin: 0;
          padding: 0;
          }
          @media (max-width: 575px) {
            margin-bottom: 20px;
          }
        }
        p {
          font-size: 40px;
          color: #fff;
          line-height: 1.1;
          max-width: 450px;
          @media (max-width: 1199px) {
            font-size: 30px;
          }
          @media (max-width: 991px) {
            font-size: 25px;
            max-width: 100%;
          }
          @media (max-width: 767px) {
            font-size: 18px;
          }
        }
      }
    }
  }

  position: relative;

  .owl-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
    padding: 15px 0;
    .owl-dot {
      span {
        background: none;
        border: 2px solid #fff;
        width: 15px;
        height: 15px;
      }
      &:focus {
        outline: none;
        box-shadow: none;
        border: 0px;
      }

      &:hover,
      &.active {
        span {
          background: #fff;
        }
      }
    }
  }
}
.main-heading {
  background: $blue;
  padding: 40px 0;
  h1{
    color: #fff;
    margin: 0;
    font-weight: 300;
  }
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  @media (max-width: 575px) {
    padding: 20px 0;
  }
}

#contact{
    .info{
        flex-basis: 100%;
        max-width: 100%;
        background: #d7d7d5;
        padding: 40px;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.6;;
        @media (max-width: 575px) {
            padding: 30px 20px;
          }
        
        .address{
            padding-left: 50px;
        }
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            margin-top: 40px;
            li{
                display: flex;
                align-items: flex-start;
                font-size: 16px;
                p{font-size: 16px;}
                i{
                    flex-basis: 30px;
                    max-width: 30px;
                    margin-right: 20px;
                    font-size: 25px;
                }
            }
        }
    }
}

.form-container{
    flex-basis: 100%;
    max-width: 100%;
    font-weight: 400;
}

#contact-form {
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }

  .form-group {
    @media (max-width: 767px) {
      margin-bottom: 7px;
    }
  }
  .form-control {
    background: #fff;
    border-radius: 0px;
    border: 1px solid #fff;
    box-shadow: none;
    font-size: 16px;
    padding: 15px 20px;
    height: 50px;
    color: #000;
    @media (max-width: 767px) {
      height: auto;
      padding: 10px 15px;
    }
    &:hover,
    &:focus {
      border-color: $lightblue;
      box-shadow: none;
      outline: 0;
    }
  }

  textarea {
    height: auto !important;
  }

  .error {
    color: darkred;
    font-style: italic;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 300;
  }
}
