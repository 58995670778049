footer{
    margin: 0;
    padding: 60px 0 20px; 
    font-weight: 100;
    @media(max-width: 767px) {
        padding: 40px 0 20px; 
    }
    background: $blue;
    color: #fff;
   .inner{
       display: flex;
       align-items: flex-start;
       @media(max-width: 991px) {
            display: block;
            text-align: center
        }
       > img{
           margin-right: 30px;
          
            @media(max-width: 991px) {
                display: block;
                height: 40px;
                margin: 0 auto;
                margin-bottom: 20px;
            }
       }
       .text{
           
            
            @media(max-width: 991px) {
                margin-bottom: 40px;
            }
            
           strong{
               display: block; 
               margin-bottom: 20px;
           }
        ul{
            li{
                a{
                    color: #fff;
                    &:hover, &:focus{
                        color: $lightblue;
                    }
                }
            }
        }

       
       }
   }


   .copyright{
       border-top: 1px solid rgba(255,255,255,0.4);
       padding-top: 20px;
       margin-top: 60px;
       @media(max-width: 991px) {
        margin-top: 0;
        padding-top: 20px;
        font-size: 15px;
        }
       ul{
           margin: 0;
           li{
            @media(max-width: 991px) {
                margin: 20px 0;
                }
           }
       }
       a{
           color: #fff;
       }

       .left-text{
           text-align: left;
           @media(max-width: 991px) {
            text-align: center
            }
       }
       .right-text{
        text-align: right;
        @media(max-width: 991px) {
            text-align: center
            }
    }
   }
}